:root {
  --indigo-dye: #2b4162ff;
  --deep-space-sparkle: #385f71ff;
  --magnolia: #f5f0f6ff;
  --burlywood: #d7b377ff;
  --french-bistre: #8f754fff;
}

body {
  margin: 0 4rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--indigo-dye);
  color: var(--burlywood);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: var(--burlywood);
  text-decoration: none;
}

a:hover,
.navItem:hover {
  color: var(--magnolia);
  text-decoration: none;
}

.navActive {
  color: var(--magnolia);
  border-bottom: 2px solid var(--french-bistre);
}

.title {
  text-align: center;
  padding: 1em;
}

.navigation {
  padding-bottom: 2em;
}

.card-overlay {
  opacity: 0;
  color: teal;
}

.project-card {
  margin-bottom: 2rem;
}

.project-card a {
  color: var(--deep-space-sparkle);
}

.project-description {
  color: var(--deep-space-sparkle);
}

.project-card:hover .card-img {
  opacity: 0.2;
  transition: 1s;
}

.project-card:hover .card-overlay {
  opacity: 1;
  transition: 1s;
}

.contact-icons {
  display: block;
  font-size: x-large;
  padding-bottom: 2em;
}

.contact-icons a {
  color: var(--magnolia);
}

.submit-btn {
  display: flex;
  justify-content: center;
  padding-bottom: 2em;
}

footer {
  display: flex;
  justify-content: space-around;
  padding: 1em;
}

footer a {
  color: var(--magnolia);
}

footer a:hover {
  opacity: 0.75;
}

.project-card a:hover,
.contact-icons a:hover,
footer a:hover {
  opacity: 0.75;
}

@media only screen and (max-width: 767px) {
  body {
    margin: 0 1rem;
  }

  .project-name,
  .project-icon {
    font-size: large;
  }

  .card-overlay {
    font-size: smaller;
  }
}
